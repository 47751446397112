import React from 'react';
import { injectIntl } from 'react-intl';
import { Timeline } from '../../../../components';
import { StaticImage } from 'gatsby-plugin-image';

const data = [
  {
    title: 'businessHowWorkStep1Title',
    content: 'businessHowWorkStep1Content',
    image: (
      <StaticImage
        src="../../../../images/how_work_read.webp"
        objectFit="cover"
        alt="desk"
        loading="lazy"
        placeholder="blurred"
      />
    ),
    mobileImage: (
      <StaticImage
        src="../../../../images/document_mob.webp"
        objectFit="cover"
        alt="desk"
        loading="lazy"
        placeholder="blurred"
      />
    ),
  },
  {
    title: 'businessHowWorkStep2Title',
    content: 'businessHowWorkStep2Content',
    image: (
      <StaticImage
        src="../../../../images/how_work_ai.webp"
        objectFit="cover"
        alt="desk"
        loading="lazy"
        placeholder="blurred"
      />
    ),
    mobileImage: (
      <StaticImage
        src="../../../../images/ia_mob.webp"
        objectFit="cover"
        alt="desk"
        loading="lazy"
        placeholder="blurred"
      />
    ),
  },
  {
    title: 'businessHowWorkStep3Title',
    content: 'businessHowWorkStep3Content',
    image: (
      <StaticImage
        src="../../../../images/how_work_group.webp"
        objectFit="cover"
        alt="desk"
        loading="lazy"
        placeholder="blurred"
      />
    ),
    mobileImage: (
      <StaticImage
        src="../../../../images/group_mob.webp"
        objectFit="cover"
        alt="desk"
        loading="lazy"
        placeholder="blurred"
      />
    ),
  },
  {
    title: 'businessHowWorkStep4Title',
    content: 'businessHowWorkStep4Content',
    image: (
      <StaticImage
        src="../../../../images/how_work_desk.webp"
        objectFit="cover"
        alt="desk"
        loading="lazy"
        placeholder="blurred"
      />
    ),
    mobileImage: (
      <StaticImage
        src="../../../../images/assigment_mob.webp"
        objectFit="cover"
        alt="desk"
        loading="lazy"
        placeholder="blurred"
      />
    ),
  },
  {
    title: 'businessHowWorkStep5Title',
    content: 'businessHowWorkStep5Content',
    image: (
      <StaticImage
        src="../../../../images/how_work_document.webp"
        objectFit="cover"
        alt="desk"
        loading="lazy"
        placeholder="blurred"
      />
    ),
    mobileImage: (
      <StaticImage
        src="../../../../images/document_ready_mob.webp"
        objectFit="cover"
        alt="desk"
        loading="lazy"
        placeholder="blurred"
      />
    ),
  },
  {
    title: 'businessHowWorkStep6Title',
    content: 'businessHowWorkStep6Content',
    image: (
      <StaticImage
        src="../../../../images/how_work_skills.webp"
        objectFit="cover"
        alt="desk"
        loading="lazy"
        placeholder="blurred"
      />
    ),
    mobileImage: (
      <StaticImage
        src="../../../../images/boss_mob.webp"
        objectFit="cover"
        alt="desk"
        loading="lazy"
        placeholder="blurred"
      />
    ),
  },
];

const HowWorkBusiness = () => {
  return <Timeline data={data} />;
};

export default injectIntl(HowWorkBusiness);
